<template>
  <b-card>
    <h4 class="mb-0">
      Jenis Pembayaran
    </h4>
    <validation-observer ref="validUpdate">
      <b-form class="mt-1" @submit.prevent="validationForm">
        <b-row>
          <b-col md="6">

            <b-form-group label="Nama" label-for="name">
              <validation-provider #default="{ errors }" name="name" rules="required">
                <b-form-input id="name" v-model="dataPayment.name" :state="errors.length > 0 ? false : null"
                  placeholder="Nama" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <validation-provider #default="{ errors }" name="Aktif" rules="required">
              <b-form-group label="Aktif" label-for="active" :state="errors.length > 0 ? false : null">
                <v-select id="active" v-model="dataPayment.active" :reduce="(active) => active.value"
                  placeholder="Pilih Ya / Tidak" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="dataIsActive" label="text" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group label="Notes" label-for="notes">
              <validation-provider #default="{ errors }" name="notes">
                <b-form-input id="notes" v-model="dataPayment.notes" :state="errors.length > 0 ? false : null"
                  placeholder="Notes" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>


          </b-col>
        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mr-1"
              @click="() => $router.go(-1)">
              Cancel
            </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary">
              Save
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  // BContainer,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BFormInvalidFeedback,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'

export default {
  name: 'DataSiswaEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BContainer,
    // BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    dataCountries,
    dataProvinces,
    dataCities,
    dataDistrics,
    dataSubDistrics,
    required,    
    dataPayment: {
      name: '',
      active: '',
      notes: '',    
    },
    dataIsActive: [
        { value: 1, text: 'Ya' },
        { value: 0, text: 'Tidak' },
      ],
  }),
  computed: {

  },
  created() {
    
  },
  methods: {    
    validationForm() {
      this.$refs.validUpdate.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line
          try {
            const studentId = this.$route.params.id
            const dataFormStudent = new FormData()
            Object.keys(this.dataPayment).forEach(key => {
              dataFormStudent.append(key, this.dataPayment[key])
            })
            await this.$http.post(`/paymentmastertypes`, dataFormStudent, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tambah Data Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'payment-type' })
          } catch (err) {
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tambah Data Tidak Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            console.log(err)
          }
        }

        // function alertnya() {
        //   alert('form submitted!')
        // }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
